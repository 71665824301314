import { LaunchStatusEvent, StreamerStatus } from "@pureweb/platform-sdk";
import { useServiceStatus } from "../hooks/useServiceStatus";
import LoadingSession from "./SessionLoading";
import ServiceUnavailable from "./ServiceUnavailable";
import ServiceNotSupported from "./ServiceNotSupported";
import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import useClientTranslation from "../hooks/useClientTranslation";

//import { Player } from 'video-react';
// import "node_modules/video-react/dist/video-react.css";

interface LoadingProps {
  launch: ({ audioEnabled }: { audioEnabled: boolean }) => void;
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

export const LoadingView = ({ LaunchRequestStatus, StreamerStatus, launch, onVideoEnd }) => {
  const { serviceNotSupported, serviceUnavailable } = useServiceStatus(LaunchRequestStatus, StreamerStatus);
  const { isTranslationSet, translation } = useClientTranslation();
  const [videoEnded, setVideoEnded] = useState(false);
  
  const playerRef = useRef<ReactPlayer>(null);

  const handleSkip = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(1, 'fraction'); // Jump to the end of the video
    }
  };

  // Check for service availability and browser support
  if (serviceNotSupported()) {
    return <ServiceNotSupported />;
  }

  if (serviceUnavailable()) {
    return <ServiceUnavailable />;
  }

  const handleVideoEnd = () => {
    console.log("login check: Video ended in LoadingView");
    setVideoEnded(true);
    if (onVideoEnd) {
      onVideoEnd();
    }
  };

  console.log(`login check: LoadingView - StreamerStatus = ${StreamerStatus}, videoEnded = ${videoEnded}`);

  // Determine the content to display based on conditions
  let content;

  if (StreamerStatus === StreamerStatus.Connected && videoEnded) {
    console.log("login check: Session ready in LoadingView");
    content = <div>Session Ready</div>;
  } else if (videoEnded) {
    console.log("login check: Video ended, session not connected in LoadingView");
    content = <LoadingSession LaunchRequestStatus={LaunchRequestStatus} />;
  } else {
    console.log("login check: Displaying video player in LoadingView");
    // Display video player
    content = (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
        <ReactPlayer
          url="https://meadow-data.s3.eu-central-1.amazonaws.com/ahead/V_Schwarz_introSound.mp4"
          playing
          ref={playerRef}
          controls={false}
          onEnded={handleVideoEnd}
          width="100%"
          height="100%"
          playsInline={true}
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          config={{
            file: {
              attributes: {
                style: {
                  objectFit: 'cover',
                  width: '100vw',
                  height: '100vh',
                },
                playsinline: true,
              },
            },
          }}
        />
      </div>
    );
  }

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
       {/* {!videoEnded && <button className="skip-button" onClick={handleSkip}>{translation.launch.skip}</button>} */}
      {content}
    </div>
  );
};