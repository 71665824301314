import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import SupportedLanguage from "../helpers/SupportedLanguages"
import client from '../client.json'

const useClientTranslation = (lang?: SupportedLanguage) => {
  const cookies = new Cookies();
  const defaultLanguage = SupportedLanguage.en; // Use English as the default language

  // If lang is undefined, use the default language
  const initialLanguage = lang !== undefined ? lang : defaultLanguage;

  const [isTranslationSet, setIsTranslationSet] = useState<boolean>(false)
  const [translation, setTranslation] = useState(client.translation[initialLanguage])

  useEffect(() => {
    // Get the language from the cookie
    let cookieLanguage = cookies.get('language');

    // If the cookie is not set, set it to the default language
    if (cookieLanguage === undefined) {
      cookieLanguage = defaultLanguage;
      cookies.set('language', defaultLanguage);
    }

    // If a language was passed, use it. Otherwise, use the language from the cookie
    const currentLanguage = lang || cookieLanguage;

    setTranslation(client.translation[currentLanguage])
    setIsTranslationSet(true);

    // Store the current language in a cookie
    cookies.set('language', currentLanguage);
  }, [lang]) // Add 'lang' to the dependency array

  return { isTranslationSet, translation }
}

export default useClientTranslation