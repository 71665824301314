import React from "react";
import Loading from "../components/Loading";
import { LaunchStatusEvent } from "@pureweb/platform-sdk";
import ScrollViewWrapper from "../ScrollViewWrapper";
import useClientTranslation from '../hooks/useClientTranslation';
import usePlatformLanguage from '../hooks/usePlatformLanguage';

export interface HumanReadable {
  [propertyName: string]: string;
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
}

const VideoBackground = () => {
  return (
    /* eslint-disable-next-line jsx-a11y/media-has-caption */
    <video autoPlay loop playsInline className="BGVideo" >     
              <source src={'https://meadow-data.s3.eu-central-1.amazonaws.com/ahead/V_Schwarz_loop_sound.mp4'} type="video/mp4" />
    </video>
  );
};

const LoadingSession: React.FC<LoadingProps> = (props: LoadingProps) => {
  const {language} = usePlatformLanguage();
  const { translation } = useClientTranslation(language);

  const humanReadableStatus: HumanReadable = {
    unknown: translation.loadingSession?.initializingConnection,
    accepted: translation.loadingSession?.acceptedRequestingModel,
    queued: translation.loadingSession?.inQueue,
    requested: translation.loadingSession?.modelRequested,
    ready: translation.loadingSession?.readyLaunchingSession,
    serviced: translation.loadingSession?.viewingModel,
    cancelled: translation.loadingSession?.cancelled,
    modelerror: translation.loadingSession?.issueWithModel,
    unavailable: translation.loadingSession?.modelDoesNotExist,
  };

  return (
    <>
      <VideoBackground />
      <div className="fixed inset-0 flex justify-center items-end w-full">
        <div className="flex flex-col items-center mb-55 text-center">
          <img className="w-24 mb-15" src="/loading.svg" alt="loading" style={{width: "150px"}}/>
          <h3 className="text-center mt-20 md:mt-80 white" style={{color:"white"}}>{humanReadableStatus[props.LaunchRequestStatus.status]}</h3>
        </div>
      </div>
    </>
  );
};

export default LoadingSession;