import React, { useCallback, useState, useEffect, useRef } from "react";
import AudioTest from "./webrtcTroubleshooter/diagnostics/AudioTest";
import PermissionsTest from "./webrtcTroubleshooter/diagnostics/PermissionsTest";
import { useWebRtcCheck } from "./hooks/useWebRtcCheck";
import useClientTranslation from "./hooks/useClientTranslation";
import usePlatformLanguage from "./hooks/usePlatformLanguage";
import { Dropdown } from "semantic-ui-react";
import ScrollViewWrapper from "./ScrollViewWrapper";
import { APPSTATES, AUDIOTESTSTATES, MICPERMISSIONSTATES } from "./constants/states";
import { appStatusService } from "./services/appStatusService";
import { audioTestService } from "./services/audioTestService";
import { micPermissionsService } from "./services/micPermissionsService";

interface AudioProps {
  appStatus: number;
  loadAudio: () => void;
  isAuthenticated: boolean;
  logout: () => void;
  setSelectedMicrophone: (selected: string) => void;
}

export const AudioView: React.FC<AudioProps> = (props: AudioProps) => {
  const [selected, setSelected] = useState<string>("");
  // const { startMicrophonePermissionTest, startAudioTest, stopAudioTest, permissionTestRunning, results, errors, status, volume } = useWebRtcCheck(selected);
  const { startMicrophonePermissionTest, startAudioTest, stopAudioTest, errors, status, volume } = useWebRtcCheck(selected);

  const launchContainer = useRef<HTMLDivElement>(null);
  const volumeBarRef = useRef<HTMLDivElement>(null);
  const { isTranslationSet, translation } = useClientTranslation();
  const [audioTestStatus, setAudioTestStatus] = useState(AUDIOTESTSTATES.NOT_STARTED);
  const [micPermissionsStatus, setMicPermissionsStatus] = useState(MICPERMISSIONSTATES.UNSET);
  // const [canRun, setCanRun] = useState<boolean>(
  //   audioTestStatus !== AUDIOTESTSTATES.RUNNING && props.isAuthenticated && errors.length === 0 && results.length > 0
  // );
  const [micInit, setMicInit] = useState<boolean>(false);
  const [volumeBarMoving, setVolumeBarMoving] = useState<boolean>(true);

  const [devices, setDevices] = useState<{ key: string; text: string; value: string }[]>([]);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(() => {
        micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.ALLOWED);
        enumerateDevices();
      })
      .catch((_e) => {
        micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.DENIED);
        console.log("audio devices could not be loaded");
      });
  }, []);
  
  useEffect(() => {
    if (selected) {
      startAudioTest(selected);
      console.log(selected);
    }
  }, [selected]);

  useEffect(() => {
    const loadHandler = () => {
      if (launchContainer?.current && isTranslationSet) {
        launchContainer.current.style.opacity = "1";
      }
    };

    loadHandler();
  }, [isTranslationSet]);

  useEffect(() => {
    props.setSelectedMicrophone(selected);
  }, [props, selected])

  useEffect(() => {
    if (audioTestStatus === AUDIOTESTSTATES.WITHOUT_MICROPHONE) {
      stopAudioTest();
    }
  }, [audioTestStatus, micPermissionsStatus, stopAudioTest]);

  useEffect(() => {
    if (!volumeBarMoving && (!micInit && audioTestStatus === AUDIOTESTSTATES.NOT_STARTED)) {
      startMicrophonePermissionTest();
      setMicInit(true);
    }

    // if (props.appStatus === APPSTATES.MICCHECKCOMPLETE || props.appStatus === APPSTATES.WITHOUTMIC) {
    //   props.updateAppStatus(APPSTATES.LOADING);
    // }
  }, [props.appStatus, audioTestStatus, micInit, startMicrophonePermissionTest, volumeBarMoving]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      /* filter audio devices */
      const audioInputs = mediaDevices.filter(({ kind }: any) => kind === "audioinput");

      /* map keys in order to fit semantic-ui-react dropdown structure */
      const mappedInputs = audioInputs.map(({ deviceId, label }: { deviceId: string; label: string }) => ({ key: deviceId, text: label, value: deviceId }));
      setDevices(mappedInputs);
      if (mappedInputs[0] !== undefined) {
        setSelected(mappedInputs[0].value.toString());
      }
    },
    [setDevices]
  );

  const enumerateDevices = () => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
  };

  useEffect(() => {
    const subscription = audioTestService.getAudioTestStatus().subscribe((n: any) => {
      setAudioTestStatus(n);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const subscription = micPermissionsService.getMicPermissions().subscribe((n: any) => {
      setMicPermissionsStatus(n);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setVolume(volume);
  }, [setVolume, volume]);

  useEffect(() => {
    if (Boolean(!status?.name) || micPermissionsStatus !== MICPERMISSIONSTATES.ALLOWED) {
      return;
    }

    if (!Boolean(errors) && audioTestStatus === AUDIOTESTSTATES.RUNNING) {
      console.log("testing mic");
      micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.TESTING_MICROPHONE);
    }

    if (isPermissionTest(status)) {
      status?.promise
        ?.then(() => {})
        .catch(() => {
          console.log("no mic permission");
          // No Permission
          micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.DENIED);
        });
    }

    if (isAudioTest(status)) {
      status?.promise
        ?.then(() => {
          console.log("audio test complete");
          // micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.READY)
        })
        .catch(() => {
          // Audio Test Failed, Input Level too low
          console.log("no mic input");
          micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.NO_MICROPHONE_INPUT);
        });
    }

    // if (canRun) {
    //   micPermissionsService.updateMicPermissions(MICPERMISSIONSTATES.READY)
    // }
  }, [props, status, errors, audioTestStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  function isAudioTest(status: any): boolean {
    // console.log("is audio text", AudioTest.AUDIO_TEST_NAME);
    return status?.name === AudioTest.AUDIO_TEST_NAME;
  }

  function isPermissionTest(status: any): boolean {
    return status?.name === PermissionsTest.MICROPHONE_PERMISSION_TEST;
  }

  function setVolume(volume: number) {
    const volumeBar = volumeBarRef?.current;
    if (!volumeBar) return;

    if (!volume) {
      setVolumeBarMoving(false);
    } else {
      setVolumeBarMoving(true);
    }

    volumeBar.style.width = calculate(volume) + "%";
  }

  /**
   * Normalize volume to 0-100
   * @param volume number
   * @returns number normalized Value
   */
  function calculate(volume: number) {
    const maxValue = -35;
    const minValue = -120;

    const normalized = map(volume, minValue, maxValue, 0, 100);
    // const diff = maxValue - minValue;

    return normalized;

    // return (volume - minValue) / diff * 100;
  }

  function clamp(input: number, min: number, max: number): number {
    return input < min ? min : input > max ? max : input;
  }

  function map(current: number, in_min: number, in_max: number, out_min: number, out_max: number): number {
    const mapped: number = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    return clamp(mapped, out_min, out_max);
  }

  return (
    <div id="launchContainer" ref={launchContainer} className="opacity-0">
      <div className="flex flex-col md:flex-row w-full min-h-screen md:h-screen">
        <div className="h-32 md:h-auto hidden md:block md:w-53 bg-brown">
          <img className="object-cover w-full h-screen" src="/mic.jpg" alt="Microphone check" />
        </div>
        <ScrollViewWrapper {...props} outerStyles={"bg-white md:w-47"} innerStyles={"w-full"}>
          <div className="flex flex-col items-center w-80 mx-auto z-0" style={{ maxWidth: "669px" }}>
            {audioTestStatus !== AUDIOTESTSTATES.COMPLETE &&
              <h1>{translation.launch.micTest}</h1>
            }
            <h2 style={{ marginTop: "30px", marginBottom: "12px" }}>
              {/* mic test failed */}
              {audioTestStatus === AUDIOTESTSTATES.FAILURE && micPermissionsStatus !== MICPERMISSIONSTATES.DENIED && translation.launch.micFailure}
              {/* mic test running or complete */}
              {audioTestStatus !== AUDIOTESTSTATES.FAILURE &&
                audioTestStatus !== AUDIOTESTSTATES.WITHOUT_MICROPHONE &&
                micPermissionsStatus !== MICPERMISSIONSTATES.DENIED &&
                audioTestStatus !== AUDIOTESTSTATES.COMPLETE &&
                translation.launch.micTesting}
              {/* mic permission forbidden */}
              {(micPermissionsStatus === MICPERMISSIONSTATES.DENIED || audioTestStatus === AUDIOTESTSTATES.WITHOUT_MICROPHONE) && translation.launch.withoutMic}
            </h2>

            {(micPermissionsStatus === MICPERMISSIONSTATES.DENIED || audioTestStatus === AUDIOTESTSTATES.WITHOUT_MICROPHONE) && translation.launch.withoutMic && (
              <>
                <div className="w-full flex justify-between">
                  <input 
                    type="button"
                    className="btn btn-white w-auto"
                    tabIndex={0}
                    onClick={() => {
                      audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.WITHOUT_MICROPHONE);
                      appStatusService.updateAppStatus(APPSTATES.MICCHECKCOMPLETE);
                    }}
                    onKeyDown={() => {
                      audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.WITHOUT_MICROPHONE);
                      appStatusService.updateAppStatus(APPSTATES.MICCHECKCOMPLETE);
                    }}
                    value={translation.launch.actions.continueButton}
                   />
                </div>
                <h4 className="mt-30 text-center px-20">{translation.launch.micAllow}</h4>
              </>
            )}

            {audioTestStatus !== AUDIOTESTSTATES.WITHOUT_MICROPHONE && audioTestStatus !== AUDIOTESTSTATES.COMPLETE && micPermissionsStatus !== MICPERMISSIONSTATES.DENIED && (
              <div className={audioTestStatus !== AUDIOTESTSTATES.FAILURE && !volumeBarMoving ? "opacity-50 pointer-events-none" : ""}>
                <div className="w-full rounded border-1 border-gray-lightest-600 overflow-hidden" style={{ marginTop: "36px", marginBottom: "39px" }}>
                  <div ref={volumeBarRef} className="w-0 transition-all duration-300 bg-brown h-20 rounded"></div>
                </div>

                <div className="flex justify-center items-center w-full">
           
                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      props.loadAudio();
                      appStatusService.updateAppStatus(APPSTATES.MICCHECKCOMPLETE);
                    }}
                    onKeyDown={() => {
                      props.loadAudio();
                      appStatusService.updateAppStatus(APPSTATES.MICCHECKCOMPLETE);
                    }}
                  >
                    <input type="radio" name="option" id="1" className="hidden" />
                    <label htmlFor="1" className="btn btn-brown block text-center cursor-pointer select-none" style={{width: "100%"}}>
                      {translation.globals.yes}
                    </label>
                  </div>
                </div>

                <div className="w-full mt-15">
                  <span className="text-white block my-20">{translation.launch.inputDevice}</span>
                  <Dropdown
                    onChange={(e, data) => {
                      data.value && setSelected(data.value.toString());
                    }}
                    placeholder={devices.length && devices[0] !== undefined ? devices[0].text : ""}
                    fluid
                    selection
                    options={devices}
                    defaultValue={devices.length && devices[0] !== undefined ? devices[0].value : ""}
                    disabled={true}
                     />
                </div>
                <div className="my-20 text-left w-full px-30 text-brown-dark">
                  <h4>{translation.launch.micHint}</h4>
                  <div className="flex justify-center mt-40">
                    <input
                      className="btn btn-small text-gray"
                      type="button"
                      onClick={() => props.logout()}
                      value={translation.AuthenticationView.backToLogin}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ScrollViewWrapper>
      </div>
    </div>
  );
};
