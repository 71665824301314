import { useEffect, useRef, useState } from "react";
import { Input } from "semantic-ui-react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";
import { Subject } from "rxjs";
import { APPSTATES } from "./constants/states";
import { appStatusService } from "./services/appStatusService";
import Checkbox from "./components/Checkbox";
import ScrollViewWrapper from "./ScrollViewWrapper";
import Auth0Form from "./Auth0Form";
import LocalAuth from "./helpers/LocalAuth";
import LocalAuthForm from "./LocalAuthForm";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationState } from "./hooks/useAuthenticationSwitch";
import { Status } from "./hooks/useGrayconTickets";
import useClientTranslation from "./hooks/useClientTranslation";
import { useConverveAuthentication } from "./hooks/useConverveAuthentication";
import useLocalAuth from "./hooks/useLocalAuth";
import usePlatformLanguage from "./hooks/usePlatformLanguage";
import { useLocation } from 'react-router-dom';
import AkamaiAuth from './helpers/AkamaiAuth';
import SupportedLanguage from "./helpers/SupportedLanguages";
//import Lottie from 'lottie-web';
import UrlHelper from "./helpers/UrlHelper"



const VideoBackground = () => {
  return (
    <video autoPlay loop muted className="BGVideo">
       <source src={'https://meadow-data.s3.eu-central-1.amazonaws.com/ahead/L_Schwarz_intro_steady.mp4'} type="video/mp4" />
    </video>
  );
};


interface AuthenticationViewProps {
  check: (code: string) => any;
  status: Status;
  emitter: InputEmitter;
  streamerStatus: StreamerStatus;
  messageSubject: Subject<string>;
  authenticationState: AuthenticationState;
  isAuthenticated: Boolean;
  localAuth: LocalAuth | undefined;
  logout: () => void;
  setStartAuthentication: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AuthenticationView(props: AuthenticationViewProps) {
  const { language, setLanguage } = usePlatformLanguage();
  const { isTranslationSet, translation } = useClientTranslation(language);
  const [isViewUpdated, setIsViewUpdated] = useState<boolean>(false);
  const launchContainer = useRef<HTMLDivElement>(null);
  const { user } = useAuth0();
  const namespace = "https://metaverse.bizzlogic.com/";
  const userMetadata = user?.[namespace + "user_metadata"];
  console.log(user);
  const [username, setUsername] = useState<string | null | undefined>();
  const [showActions, setShowActions] = useState(false);


  
/* const animationRef = useRef<any>(null);

useEffect(() => {
  const animationData = language === 'en' ? animationDataEN : animationDataDE;

  // Create a new MutationObserver instance
  const observer = new MutationObserver((mutationsList, observer) => {
    // Look through all mutations that just occured
    for(let mutation of mutationsList) {
      // If the removedNodes property has one or more nodes
if(mutation.removedNodes.length) {
  for(let node of mutation.removedNodes) {
    if(node instanceof HTMLElement && node.id === 'lottie') {
      // Destroy the previous animation if it exists
      if (animationRef.current) {
        console.log("lottieDestroy");
        animationRef.current.destroy();
      }
    }
  }
}

// If the addedNodes property has one or more nodes
if(mutation.addedNodes.length) {
  for(let node of mutation.addedNodes) {
    if(node instanceof HTMLElement && node.id === 'lottie') {
      animationRef.current = Lottie.loadAnimation({
        container: node,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
      });

      animationRef.current.play();
      console.log("lottiePlay");
    }
  }
}
    }
  });

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  // Clean up the observer when the component is unmounted
  return () => observer.disconnect();
}, [language]);
 */
useEffect(() => {
  setTimeout(() => {
    setShowActions(true);
  }, 4000);
}, []);

  const updateIsViewUpdated = (state: boolean) => {
    setIsViewUpdated(state);
  };

  useEffect(() => {
    const getUsername = () => {
      if (!props.isAuthenticated) return;

      switch (props.authenticationState) {
        case AuthenticationState.AUTH0:
          return user ? userMetadata?.Name : null;
        case AuthenticationState.AKAMAI:
          return AkamaiAuth.getFullName();
        case AuthenticationState.LOCAL:
          return props.localAuth?.name;
        default:
          return;
      }
    };
    setUsername(getUsername);
  }, [props.authenticationState, props.isAuthenticated, props.localAuth, user]);

  useEffect(() => {
    const loadHandler = () => {
      if (launchContainer?.current && isTranslationSet) {
        if (launchContainer.current) {
          launchContainer.current.style.opacity = "1";
        }
      }
    };
  
    window.addEventListener("load", loadHandler);
    return () => {
      document.removeEventListener("load", loadHandler);
    };
  }, [isTranslationSet]);

  //let helper = new UrlHelper(window.location.href)

  const Actions = () => {
    useEffect(() => {
      let helper = new UrlHelper(window.location.href);
      if (helper.hasParams()) {
        props.setStartAuthentication(true);
      }
    }, []);
    
    if (!props.isAuthenticated) {
      return (

        <div className={`flex justify-center items-center w-full`} style={{  margin: "0 0 -30px 0"  }}>
          <img src="/box_Le.png" alt="box le" style={{ padding: "20px", width: "100px", margin: "0 0 0 -100px" }} />
        <input
          onClick={() => props.setStartAuthentication(true)}
          className={`btn btn-brown flex-1`}
          type="button"
          placeholder={translation.AuthenticationView.loginSSO}
          value={translation.AuthenticationView.loginSSO}
        />
        <img src="/box_ri.png" alt="box le" style={{  padding: "20px", width: "100px",  margin: "0 -100px 0 0"  }} />
       </div>

      );
    } else {
    return (

        <div className={`flex justify-center items-center w-full`} style={{  margin: "0 0 -30px 0"  }}>
           <img src="/box_Le.png" alt="box le" style={{ padding: "20px", width: "100px", margin: "0 0 0 -100px" }} />
        <input
          onClick={() => appStatusService.updateAppStatus(APPSTATES.LOADING)}
          className={`btn btn-brown flex-1 `}
          type="button"
          placeholder={translation.launch.actions.login}
          value={translation.launch.actions.continueButton}
        />
         <img src="/box_ri.png" alt="box le" style={{  padding: "20px", width: "100px", margin: "0 -100px 0 0"   }} />
      </div>

    ); 
  }
  };

  const LanguageButtons = () => {
    return (
      <div className={`flex justify-center items-center w-full`}  >
        <button
          onClick={() => {
            setLanguage(SupportedLanguage.de);
            toggleDisclaimer();
          }}
          className={`btn btn-brown flex-1 md:max-w-1/2 pr-2 border-r border-gray-400`}
          disabled={translation.language === "de"}
        >
          DEUTSCH
        </button>
        <img src="/divider.png" alt="box le" style={{  padding: "20px", width: "60px"  }} />
        <button
          onClick={() => {
            setLanguage(SupportedLanguage.en);
            toggleDisclaimer();
          }}
          className={`btn btn-brown flex-1 md:max-w-1/2 pl-2`}
          disabled={translation.language === "en"}
        >
          ENGLISH
        </button>
      </div>
    );
  };

  const Loading = () => {
    return <h3 className="md:text-2xl xxl:4xl " style={{ margin: "0 0 150px 0"}}>{translation.AuthenticationView.loading}</h3>;
  };

  const Headline = () => {
    return <h1 className="md:text-2xl xxl:4xl">{translation.AuthenticationView.title}</h1>;
  };

  const IntroImage = () => {
    return (
      <div className="h-32 md:h-auto w-53 hidden md:block">
        <img className="object-cover w-full h-screen" src="/intro.jpg" alt="img" />
      </div>
    );
  };

  const LottieRender = () => {
    return  <div id="lottie" style={{ width: "100%" }}>
  </div> };


  const Logo = () => {
    return <img alt="Bizzlogic Logo" src="/schwarz_logo.png" className=" mx-auto" style={{ marginBottom: "27px", width: "120px" }} />;
  };

  const HeadlineImage = () => {
    return (
      <div style={{ position: 'relative', width: '100vw', left: '50%', right: '50%', marginLeft: '-50vw', marginRight: '-50vw' }}>
      <img alt="tagline" src={translation.tagline} className="mx-auto" style={{ width: "80%" }} />
    </div>
    );
  };

  const [showImprint, setShowImprint] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const toggleImprint = () => {
    setShowImprint(!showImprint);
  };

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };
  const lines = translation.AuthenticationView.imprintContent.split('\n');
  const DiscDotted = translation.AuthenticationView.DisclaimerList.split('\n');

  const ImprintDiv = () => {
    if (!showImprint) return null;
    return (
      <button
      onClick={toggleImprint}
      className="imprint-container"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto', // Adjust as needed
        maxWidth: '80%', // Prevents the div from being too wide on large screens
        backgroundColor: 'rgba(255,255,255,0.9)',
        zIndex: 1000,
        padding: '50px',
        borderRadius: '0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'left', // Align text to the left
        overflowY: 'auto', // Allows scrolling if the content is too long
        maxHeight: '80vh', // Limit the height, making it scrollable
        cursor: 'default', // Default cursor on the button
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
      }}
    >
      <div className="imprint-text"  style={{color: 'black', textAlign: 'center'}}>
      <h2><strong>{translation.AuthenticationView.imprintHeader}</strong></h2><br />
      {lines.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
     </div>
      <div
    style={{
      position: 'absolute',
      color: "black",
      fontWeight: 'bold',
      top: '10px', // Adjust as needed
      right: '10px', // Adjust as needed
      cursor: 'pointer' // Changes cursor to pointer when hovering over the close icon
    }}
  >
    X
  </div>
    </button>
    
    );
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const DisclaimerDiv = () => {
    if (!showDisclaimer) return null;
    return (
      <button
      onClick={undefined}
      className="imprint-container"
      disabled={false}
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '96vw' : 'auto',
        maxWidth: isSmallScreen ? 'none' : '80%',
        backgroundColor: 'rgba(255,255,255,0.9)',
        zIndex: 1000,
        padding: '50px',
        borderRadius: '0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'left', // Align text to the left
        overflowY: 'auto', // Allows scrolling if the content is too long
        height: '96vh', // Limit the height, making it scrollable
        cursor: 'default', // Default cursor on the button
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
      }}
    >
      <div className="imprint-text"  style={{color: 'black', textAlign: 'center'}}>
      <h2 style={{fontSize:"22px"}}><strong>{translation.AuthenticationView.DisclaimerHeader}</strong></h2><br />
      {translation.AuthenticationView.DisclaimerContentA}
      
      <ul style={{listStyleType:"disc", textAlign: "left"}}>

      {DiscDotted.map((line, index) => (
        <li key={index} style={{margin:"20px 0 20px 0"}} dangerouslySetInnerHTML={{ __html: line }}></li>
      ))}
      </ul>
      <div style={{margin:"30px 0 10px 0"}}>{translation.AuthenticationView.DisclaimerContentB}</div>
      <div>{translation.AuthenticationView.DisclaimerContentC}</div>
      <div style={{margin:"0 0 10px 0"}}>{translation.AuthenticationView.DisclaimerContentD}</div>
      
      <button
      style={{margin: "40px 0 0 0"}}
    onClick={toggleDisclaimer}
    className={`btn btn-black justify-center`}
    disabled={false}
  >
    {translation.AuthenticationView.DisclaimerButton}
  </button>
  </div>
    </button>
 
    );
  };



  const Subtitle = () => {
    if (props.isAuthenticated) {
      return <h2>{translation.launch.subtitle}</h2>;
    } else {
      return (
        <h2 className="text-center md:text-left" style={{ marginTop: "5px", marginBottom: "35px" }}>
          {props.authenticationState === AuthenticationState.AUTH0 ? translation.launch.subtitle : translation.launch.activate}
        </h2>
      );
    }
  };

  const Welcome = () => {
    if (!props.isAuthenticated) {
      return <h1 className="mt-80 text-center"> </h1>;
    } else {
    return (
      <p className="mt-80 text-center">
        {translation.AuthenticationView.welcome} {username + "!"}
      </p>
    ); 
  }
  };

  const AuthError = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error === 'unauthorized' && errorDescription) {
      setErrorMessage(decodeURIComponent(errorDescription));
    }
  }, [location]);

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );

  };

    return (
      <div id="launchContainer" ref={launchContainer} className="transition duration-1000 bg-fullscreen-bg bg-cover bg-center bg-no-repeat bg-fixed ">
           <VideoBackground />
           <ImprintDiv /> 
           <DisclaimerDiv /> {/* Imprint Div */}
      <button
        onClick={toggleImprint}
        style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          left: '50%',
          transform: 'translateX(-50%)',
          // Further styling here
        }}
      >
       {translation.AuthenticationView.imprint}
      </button>
      <div className="flex flex-col md:flex-row w-full min-h-screen md:h-screen items-start justify-center h-full">
          <ScrollViewWrapper {...props} isViewUpdated={isViewUpdated} outerStyles={"w-full"} innerStyles={"w-full"}>

         
          <div className="relative flex flex-col items-center mx-auto z-0 w-90 md:w-80 flex-grow justify-between h-full">
  <div>
    <Logo />
    
    <AuthError />
  
  </div>

  {showActions ? (
    <div className="items-center w-80">
      <HeadlineImage />
      <Welcome />
      <div className="mx-auto w-full sm:w-full md:w-full lg:w-full xl:w-auto" style={{ maxWidth: '440px' }}>
        <Actions />
      <LanguageButtons />
      </div>
    </div>
  ) : (
    <Loading />
  )}
</div>
          </ScrollViewWrapper>
        </div>
    </div>
  );
}

const AuthenticationForm = (props: any) => {
  const [code, setCode] = useState<string>("");
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);
  const { isAuthenticated, code: converveToken } = useConverveAuthentication(props.authenticationState === AuthenticationState.CONVERVE);
  const { authenticate } = useLocalAuth();

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.GC || props.status !== Status.OK) return;
  }, [props.status, props.authenticationState]);

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.CONVERVE) return;

    setCode(converveToken);
  }, [converveToken, isAuthenticated, props.authenticationState]);

  const localAuthSave = (name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string) => {
    authenticate(name, job, occupation, interests, country, companyName, email);
    appStatusService.updateAppStatus(APPSTATES.LOADING);
  };

  switch (props.authenticationState) {
    case AuthenticationState.AUTH0:
      return <Auth0Form {...props} />;
    case AuthenticationState.CONVERVE:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.converve.title}</h1>
          </div>
          <div id="codecontainer">
            {!Boolean(code) && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveNoCode}</h3>}
            {!isAuthenticated() && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveWrongCode}</h3>}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.AKAMAI:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.converve.title}</h1>
          </div>
          <div id="codecontainer">
            {!Boolean(code) && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveNoCode}</h3>}
            {!isAuthenticated() && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveWrongCode}</h3>}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GC:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.gc.title}</h1>
          </div>
          <div id="codecontainer">
            <h1>{translation.AuthenticationView.logintitle}</h1>
            <h3>{translation.AuthenticationView.enterCode}</h3>
            <Input type="text" placeholder="Code" onChange={(e) => setCode(e.target.value)} />
            <br />
            {props.status === Status.NOT_FOUND && <h3>{translation.AuthenticationView.noCodeFound}</h3>}
            {props.status === Status.OK && <h3>{translation.AuthenticationView.codeOk}</h3>}
            {/* { !props.loading && props.status !== Status.OK && <Button onClick={() => props.check(code)}>{ translation.AuthenticationView.button }</Button> } */}
            {props.status === Status.OK && code && <LocalAuthForm onSave={localAuthSave} code={code} />}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GOOGLE:
      return (
        <div>
          <button className="btn text-darkblue pt-13 pb-13 focus:outline-brown">
            <img src="google.svg" alt="Google Logo" className="inline-block w-35 h-35 mr-20" />
            Login with Google
          </button>
          <div className="w-full flex items-center mt-20 mb-18">
            <div className="flex-grow h-1 bg-brown"></div>
            <span className="flex-shrink px-13 text-brown-dark">Or login with email</span>
            <div className="flex-grow h-1 bg-brown"></div>
          </div>

          <input type="email" placeholder="Email" name="email" className="btn" />
          <input type="password" placeholder="Password" name="pass" className="btn" />

          <div className="w-full flex flex-col md:flex-row justify-between mt-8 mb-20 space-y-20 md:space-y-0">
            <Checkbox>Keep me logged in</Checkbox>
            <Checkbox>Forgot your password?</Checkbox>
          </div>

          <input
            onClick={() => appStatusService.updateAppStatus(APPSTATES.MICCHECK)}
            className="btn btn-brown cursor-pointer"
            type="submit"
            placeholder={translation.launch.actions.login}
          />

          <div id="signup" className="text-center">
            <span className="text-gray-light">Haven’t sign up yet?</span>{" "}
            <span
              className="text-brown rounded hover:text-brown-dark focus:text-brown-dark focus:outline-brown-narrow"
              role="button"
              tabIndex={0}
              onClick={() => {
                props.updateIsViewUpdated(true);
              }}
              onKeyDown={() => {
                props.updateIsViewUpdated(true);
              }}
            >
              Sign up
            </span>
          </div>
        </div>
      );
    case AuthenticationState.LOCAL:
      return <LocalAuthForm onSave={localAuthSave} {...props} />;
    default:
      return <></>;
  }
};
