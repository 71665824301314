import { useRef, useState, useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useIsIphone } from "./hooks/useIsIphone";
import { useIsiOSMobile } from "./hooks/useIsiOSMobile";
import { LoadingView } from "./LoadingView/LoadingView";
import { useAndroidMobile } from "./hooks/useAndroidMobile";
import FullScreenBtn from "./components/FullScreenBtn";
import RotateScreen from "./components/RotateScreen";
import Stream from "./components/Stream";
import { Subject } from "rxjs";
import scrollHand from "./assets/images/scroll.png";
import UETS from "./hooks/useUnrealTeamspeakExchange";

import {
  LaunchStatusEvent,
  InputEmitter,
  StreamerStatus,
  ISignallingConnection,
  Resolution,
  streamResolutionConfiguration
} from "@pureweb/platform-sdk";

import { useUnrealLoadingScreenEndListener } from "./hooks/useUnrealLoadingScreenEndListener";
import { IdleTimeout, VideoStream } from "@pureweb/platform-sdk-react";

interface ViewProps {
  launch: ({ audioEnabled }: { audioEnabled: boolean }) => void;
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  SignallingConnection?: ISignallingConnection;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  MessageSubject: Subject<string>;
  PointerLockRelease: boolean;
  ScrolledDown: boolean;
  Resolution: Resolution;
}


export const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const handle = useFullScreenHandle();
  const [videoEnded, setVideoEnded] = useState(false);
  const [showMainContent, setShowMainContent] = useState(false);
  const [loadingScreenEnded, setLoadingScreenEnded] = useState(false);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  const handleLoadingScreenEnd = () => {  
    setLoadingScreenEnded(true);
  };
  

  useUnrealLoadingScreenEndListener(props.InputEmitter, props.StreamerStatus, props.MessageSubject , () => handleLoadingScreenEnd() );

  useEffect(() => {
    // Check if video has ended but showMainContent is not yet set
    if (videoEnded && !showMainContent && loadingScreenEnded) {
      // Apply the delay only when the StreamerStatus becomes Connected
      if (props.StreamerStatus === StreamerStatus.Connected) {
        console.log("starting timeout");
        const timer = setTimeout(() => {
          setShowMainContent(true);
        }, 10); // Delay for 2 seconds
  
        return () => clearTimeout(timer);
      }
    } else if (!videoEnded) {
      // Reset showMainContent when video is not ended
      setShowMainContent(false);
    }
  }, [videoEnded, loadingScreenEnded, props.StreamerStatus]);

  useEffect(() => {
    if (!props.UsePointerLock) return;

    const currentVideo = videoRef.current;
    if (currentVideo == null) return;
    if (props.StreamerStatus !== StreamerStatus.Connected) return;

    const handler = (_e: MouseEvent) => {
      currentVideo.requestPointerLock();
    };

    currentVideo.addEventListener("click", handler);
    return () => {
      currentVideo.removeEventListener("click", handler);
    };
  }, [props.UsePointerLock, props.StreamerStatus]);

  const isIPhone = useIsIphone();
  const isIOSMobile = useIsiOSMobile();
  const isAndroid = useAndroidMobile();

  const renderContent = () => {
    if (!videoEnded || !showMainContent) {
      return (
        <LoadingView 
          LaunchRequestStatus={props.LaunchRequestStatus} 
          StreamerStatus={props.StreamerStatus} 
          launch={props.launch} 
          onVideoEnd={handleVideoEnd}
        />
      );
    }

    if (showMainContent) {
      return (
        <>
          <VideoStream
            VideoRef={videoRef}
            Emitter={props.InputEmitter}
            Stream={props.VideoStream}
            UseNativeTouchEvents={props.UseNativeTouchEvents}
            UsePointerLock={props.UsePointerLock}
            PointerLockRelease={props.PointerLockRelease}
            Resolution={streamResolutionConfiguration(props.Resolution)}
          />

          <Stream emitter={props.InputEmitter} messageSubject={props.MessageSubject} />
          {!isIPhone && isAndroid && !handle.active && props.StreamerStatus === StreamerStatus.Connected && <FullScreenBtn handle={handle} />}
          
          {process.env.REACT_APP_VOICE === "TS" && 
            <UETS emitter={props.InputEmitter} messageSubject={props.MessageSubject} streamerStatus={props.StreamerStatus} />
          }
        </>
      );
    }

    return <div>Loading...</div>;
  };

  return (
    <FullScreen handle={handle}>
      <IdleTimeout
        Status={props.StreamerStatus}
        WarningThreshold={600}
        ExitThreshold={120}
        WarningCallback={() => handle.exit}
        ExitCallback={() => window.location.reload()}
      />

      {renderContent()}

      {/* {props.StreamerStatus === StreamerStatus.Connected && isIOSMobile && !props.ScrolledDown && <img id="scrollHand" src={scrollHand} alt="scroll" />} */}
      {props.StreamerStatus === StreamerStatus.Connected && <RotateScreen />}
    </FullScreen>
  );
};
